<template>
  <a-modal
    title="数据字典编辑"
    :width="750"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="字典代码" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                type="text"
                disabled
                v-decorator="['code', { rules: [{ required: true, message: '请输入字典代码，唯一' }] }]"
                placeholder="请输入字典代码，唯一"
              />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="字典名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                type="text"
                v-decorator="['name', { rules: [{ required: true, message: '请输入字典名称' }] }]"
                placeholder="请输入字典名称"
              />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="描述" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input type="text" v-decorator="['dictDescribe']" placeholder="请输入字典描述" />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="字典值" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input type="text" v-decorator="['dictValue']" placeholder="请输入字典值" />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                v-decorator="['sort', { rules: [{ required: true, message: '请输入排序值' }] }]"
                placeholder="请输入排序值"
                style="width: 100%"
              />
            </a-form-item>
          </a-form>
        </a-col>
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <div style="width: 104px; height: 104px">
                <a-upload
                  style="margin: auto; position: relative"
                  name="avatar"
                  accept=".png,.jpg,jpeg,.gif"
                  list-type="picture-card"
                  :show-upload-list="false"
                  :customRequest="customUserRequest"
                  v-decorator="['picture']"
                >
                  <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                  <div v-else>
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                  </div>
                  <div style="position: absolute; top: 5px; left: 5px" v-if="picture">
                    <a-icon style="font-size: 16px" type="close-circle" theme="twoTone" @click.stop="delPic" />
                  </div>
                </a-upload>
              </div>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { dictEdit, dictDetail } from '@/api/modular/mallLiving/dataDict'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'

export default {
  data() {
    return {
      previewVisible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      orgTree: [],
      orgList: [],
      posList: [],
      sysEmpParamExtList: [],
      memberLoading: false,
      form: this.$form.createForm(this),
      data: [],
      birthdayString: [],
      specList: [],
      picture: '',
      parentId: '',
      pictureLoading: false,
      dictId: '',
    }
  },
  methods: {
    delPic() {
      this.picture = ''
      this.form.setFieldsValue({
        picture: undefined,
      })
    },
    preVisible() {
      this.previewVisible = true
    },
    Cancelpreview() {
      this.previewVisible = false
    },
    formatFileName() {
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''

      for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n + new Date().getTime()
    },
    //自定义图片上传实现
    customUserRequest(filedata) {
      let that = this
      this.pictureLoading = true
      console.log(filedata)
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      })
        .then((res) => {
          console.log('res', res)
          if (res.res.statusCode == 200) {
            var list = res.res.requestUrls[0].split('?')
            if (list.length > 0) {
              this.picture = list[0]
              this.pictureLoading = false
            }
          }
        })
        .finally(() => {
          this.pictureLoading = false
        })
    },
    // 初始化方法
    edit(record) {
      let that = this
      //下面是正常的初始化
      this.visible = true
      this.confirmLoading = true
      this.dictId = record.id
      //调取详情接口获取回显数据
      dictDetail({ id: this.dictId })
        .then((res) => {
          if (res.success) {
            var details = res.data
            setTimeout(() => {
              // 回显数据
              this.picture = details.images
              this.parentId = details.parentId
              this.form.setFieldsValue({
                code: details.code,
                name: details.name,
                dictValue: details.dictValue,
                dictDescribe: details.dictDescribe,
                picture: details.images,
                sort: details.sort,
              })
              this.confirmLoading = false
            }, 200)
          } else {
            setTimeout(() => {
              this.confirmLoading = false
            }, 6000)
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.confirmLoading = false
          }, 6000)
        })
    },

    handleSubmit() {
      const {
        form: { validateFields },
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          var params = {
            name: values.name,
            dictValue: values.dictValue ? values.dictValue : '',
            code: values.code,
            dictDescribe: values.dictDescribe ? values.dictDescribe : '',
            images: this.picture ? this.picture : '',
            parentId: this.parentId,
            id: this.dictId,
            sort: values.sort,
          }
          this.confirmEditDictData(params)
        } else {
          this.confirmLoading = false
        }
      })
    },

    confirmEditDictData(values) {
      dictEdit(values)
        .then((res) => {
          if (res.success) {
            this.$message.success('字典数据编辑成功')
            this.confirmLoading = false
            this.$emit('ok', values)
            this.visible = false
            this.handleCancel()
          }
        })
        .finally((err) => {
          this.confirmLoading = false
        })
    },
    handleCancel() {
      this.form.resetFields()
      this.picture = ''
      this.visible = false
      this.confirmLoading = false
      // 清理子表单中数据
      this.data = []
      // 清理时间
      this.birthdayString = []
    },
  },
}
</script>
<style scoped>
.btn {
  position: absolute;
  left: 145px;
  top: 35px;
  width: 80px;
  font-size: 15px;
}
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
